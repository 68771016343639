import s, { InferSchema } from "@mobiltracker/schema-validation";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { apiUrl } from "src/config";
import { PostRouteRequest } from "src/interfaces/Routes/PostRouteRequest";
import { Recurrence } from "src/screens/Recurrences/RecurrenceDetails";
import { RootState } from "src/store/types";
import { CheckVanCanBeDeletedSchema } from "src/types/CheckVanCanBeDeletedSchema";
import { DriverSchema } from "src/types/DriverSchema";
import { fullExecutedRouteSchema } from "src/types/ExecutedRouteSchemas";
import { GetInstallmentsResponseSchema } from "src/types/GetInstallmentsResponseSchema";
import { GetParentsResponseSchema } from "src/types/GetParentsResponseSchema";
import { getRoutesResponseSchema } from "src/types/GetRoutesResponseSchema";
import { GetSchoolsResponseSchema } from "src/types/GetSchoolsResponseSchema";
import { GetStudentsResponseSchema } from "src/types/GetStudentsResponseSchema";
import { InstallmentStatus } from "src/types/Installment";
import { InvoicesSchema } from "src/types/InvoicesSchema";
import {
  QueryOptionsEndpoint,
  ResourceQueryOptionsSchema,
} from "src/types/ResourceSchemas";
import { RetrieveResource } from "src/types/RetrieveResource";
import { ScopesApiResponseSchema } from "src/types/Scopes";
import { ResourceQueryOptions } from "src/types/SmartListQueryOptions";
import {
  DriverPagedItemSchema,
  ExecutedRoutePagedItemSchema,
  OwnerRoutesListPagedItemSchema,
  StudentsPagedItemSchema,
  ParentsPagedItemSchema,
  VansPagedItemSchema,
  SchoolsPagedItemSchema,
} from "src/types/SmartListSchemas";
import { subscriptionSchema } from "src/types/SubscriptionSchema";
import { CurrentRoute, CurrentRoutesSchema } from "../types/CurrentRoute";
import { Parent } from "../types/Parent";
import { Route } from "../types/Route";
import { School } from "../types/School";
import { Student } from "../types/Student";
import { Van, VansSchema } from "../types/Van";
import { normalizeString } from "src/utils/normalizeString";
import { CreateStudentResponseSchema } from "src/types/CreateStudentResponseSchema";
import { CreateDriverResponseSchema } from "src/types/CreateDriverResponseSchema";
import { CreateRouteResponseSchema } from "src/types/CreateRouteResponseSchema";
import { Address } from "src/types/AddressSchema";
import { LoginResponseSchema } from "src/types/LoginResponseSchema";
import { loggedInAction } from "src/actions/login";
import { CreateRecurrenceRequest } from "../types/CreateRecurrenceRequest";
import {
  AccountInfo,
  accountInfoSchema,
  OrganizationInfo,
} from "src/types/AccountInfo";
import { completedTutorialsSchema } from "src/types/CompletedTutorials";
import { Location } from "src/facades/maps";
import {
  getSuggestionsResponseSchema,
  PostSuggestionResponse,
} from "src/types/getSuggestionsResponse";
import { PagedParentSchema, ParentSchema } from "src/types/ParentsSchemas";
import { KeycloakLoginResponseSchema } from "src/types/KeycloakLoginResponseSchema";

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState;

    if (state.fakeAuth.enabled) {
      headers.set("Authorization", `Bearer ${state.fakeAuth.token}`);
    } else if (state.login.authenticated && state.login.accessToken) {
      headers.set("Authorization", `Bearer ${state.login.accessToken}`);
    }

    return headers;
  },
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;

  const baseUrl = state.fakeAuth.enabled ? state.fakeAuth.apiUrl : apiUrl;
  const keycloakUrl =
    "https://keycloak.dev.mobiltk.com.br/realms/myrealm/protocol/openid-connect/token";

  const adjustedArgs =
    typeof args === "string"
      ? `${baseUrl}/${args}`
      : args.url === "keycloak"
      ? { ...args, url: keycloakUrl }
      : { ...args, url: `${baseUrl}/${args.url}` };

  return baseQuery(adjustedArgs, api, extraOptions);
};

function providesList<IDS extends (string | number)[], T extends string>(
  ids: IDS | undefined,
  tagType: T
) {
  return ids
    ? [
        { type: tagType, id: "LIST" },
        ...ids.map((id) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

const tags = [
  "currentRoute",
  "filter-options",
  "query-options",
  "installment",
  "parent",
  "recurrence",
  "route",
  "routes-history",
  "school",
  "student",
  "van",
  "driver",
  "account-info",
  "tutorials",
  "suggestions",
  "scopes",
] as const;

const vanescolaApi = createApi({
  reducerPath: "vanescolaApi",
  baseQuery: dynamicBaseQuery,
  tagTypes: tags,
  endpoints: (builder) => ({
    updateAccountActivation: builder.mutation({
      query: (args: { email: string; token: string }) => ({
        url: `activation`,
        method: "PUT",
        body: args,
      }),
    }),

    resendAccountActivation: builder.mutation({
      query: (args: { email: string }) => ({
        url: `resend/registration`,
        method: "PUT",
        body: args,
      }),
    }),

    createRecurrence: builder.mutation({
      query: (args: CreateRecurrenceRequest) => ({
        url: "owner/recurrence",
        method: "POST",
        body: args,
      }),
      invalidatesTags: [
        { type: "recurrence", id: "LIST" },
        { type: "installment", id: "LIST" },
      ],
    }),
    deleteRecurrence: builder.mutation({
      query: (args: { recurrenceId: string }) => ({
        url: `owner/recurrence?recurrenceId=${args.recurrenceId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { recurrenceId }) => [
        { type: "recurrence", id: recurrenceId },
        { type: "installment", id: "LIST" },
      ],
    }),

    getVans: builder.query<Van[], void>({
      query: () => ({
        url: "owner/vans",
      }),
      transformResponse: (base) => VansSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((van) => van.vanId),
          "van"
        ),
    }),

    getResourceQueryOptions: builder.query<
      ResourceQueryOptions,
      { endpoint: QueryOptionsEndpoint }
    >({
      query: ({ endpoint }) => ({ url: endpoint }),
      transformResponse: (base) => ResourceQueryOptionsSchema.parse(base),
      providesTags: (_response, _data, query) => [
        { type: "query-options", id: query.endpoint },
      ],
    }),

    retrieveFilteredVans: builder.query<
      InferSchema<typeof VansPagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/vans/filtered",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => VansPagedItemSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.items.map((v) => v.vanId),
          "van"
        ),
    }),

    retrieveFilteredDrivers: builder.query<
      InferSchema<typeof DriverPagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/drivers/filtered",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => DriverPagedItemSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.items.map((d) => d.driverId),
          "driver"
        ),
    }),

    createVan: builder.mutation({
      query: (data: { name: string }) => ({
        url: "owner/vans",
        method: "POST",
        body: data,
      }),
      invalidatesTags: () => [{ type: "van", id: "LIST" }],
    }),

    updateVan: builder.mutation({
      query: (data: {
        vanId: number;
        name: string;
        isMtkIntegrationActive?: boolean;
        driverIds: string[];
      }) => ({
        url: "owner/vans/",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (_result, _error, van) => [
        { type: "van" as const, id: van.vanId },
        ...van.driverIds.map((id) => ({ type: "driver" as const, id })),
      ],
    }),

    checkVanCanBeDeleted: builder.query({
      query: (vanId: number) => ({
        url: "owner/vans/check-delete",
        params: {
          vanId,
        },
      }),
      transformResponse: (result) => CheckVanCanBeDeletedSchema.parse(result),
    }),

    deleteVan: builder.mutation({
      query: (data: { vanId: number; driverIds: string[] }) => ({
        url: "owner/vans/",
        method: "DELETE",
        params: {
          vanId: data.vanId,
        },
      }),
      invalidatesTags: (result, error, van) => [
        { type: "van" as const, id: van.vanId },
        ...van.driverIds.map((id) => ({ type: "driver" as const, id })),
      ],
    }),

    getCurrentRoutes: builder.query<CurrentRoute[], void>({
      query: () => ({
        url: "owner/current-routes",
      }),
      transformResponse: (base) => CurrentRoutesSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((r) => r.vanId),
          "currentRoute"
        ),
    }),

    createRoute: builder.mutation({
      query: (route: PostRouteRequest) => ({
        url: "owner/routes",
        method: "POST",
        body: route,
      }),
      invalidatesTags: [{ type: "route", id: "LIST" }, "tutorials"],
      transformResponse: (base) => CreateRouteResponseSchema.parse(base),
    }),

    deleteRoute: builder.mutation({
      query: (routeId: number) => ({
        url: `owner/routes/${routeId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, routeId) => [
        { type: "route", id: routeId },
      ],
    }),

    updateRoute: builder.mutation({
      query: ({ schedulerTime, routeItems, ...route }: Route) => ({
        url: `owner/routes/${route.id}`,
        method: "PUT",
        body: {
          ...route,
          time: schedulerTime,
          routeItems: routeItems.map(({ schedulerTime, ...rest }) => ({
            ...rest,
            time: schedulerTime,
          })),
        },
      }),
      invalidatesTags: (_result, _error, route) => [
        { type: "route", id: route.id },
      ],
    }),

    getStudents: builder.query<Student[], void>({
      query: () => "owner/students",
      transformResponse: (base) =>
        GetStudentsResponseSchema.parse(base).sort((studentA, studentB) => {
          const nameA = normalizeString(studentA.name);
          const nameB = normalizeString(studentB.name);
          return nameA.localeCompare(nameB);
        }),
      providesTags: (result) =>
        providesList(
          result?.map((s) => s.id),
          "student"
        ),
    }),

    retrieveFilteredStudents: builder.query<
      InferSchema<typeof StudentsPagedItemSchema>,
      RetrieveResource
    >({
      query: (args) => ({
        method: "POST",
        url: "owner/students/list",
        body: args,
      }),
      transformResponse: (response) => StudentsPagedItemSchema.parse(response),
      providesTags: (result) =>
        providesList(
          result?.items.map((s) => s.id),
          "student"
        ),
    }),

    updateStudent: builder.mutation({
      query: (args: {
        studentId: number;
        name: string;
        schoolId: number;
        isActive: boolean;
      }) => ({
        url: `owner/student/${args.studentId}`,
        method: "PUT",
        body: args,
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "student",
          id: args.studentId,
        },
      ],
    }),

    deleteStudent: builder.mutation({
      query: (args: { studentId: number }) => ({
        url: `owner/student/${args.studentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "student",
          id: args.studentId,
        },
      ],
    }),

    createStudent: builder.mutation<
      s.infer<typeof CreateStudentResponseSchema>,
      { studentName: string; schoolId: number }
    >({
      query: ({ schoolId, studentName }) => ({
        url: "owner/student",
        method: "POST",
        body: { name: studentName, schoolId },
      }),
      invalidatesTags: () => [
        {
          type: "student",
          id: "LIST",
        },
        "tutorials",
      ],
      transformResponse: (base) => CreateStudentResponseSchema.parse(base),
    }),

    createStudentParentAssociation: builder.mutation({
      query: (args: { parentId: string; studentId: number }) => ({
        url: `owner/parents/${args.parentId}/students/${args.studentId}`,
        method: "POST",
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "student",
          id: args.studentId,
        },
        {
          type: "parent",
          id: args.parentId,
        },
      ],
    }),

    deleteStudentParentAssociation: builder.mutation({
      query: (args: { parentId: string; studentId: number }) => ({
        url: `owner/parents/${args.parentId}/students/${args.studentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "student",
          id: args.studentId,
        },
        {
          type: "parent",
          id: args.parentId,
        },
      ],
    }),

    createParent: builder.mutation({
      query: ({
        parentName,
        parentPhone,
        studentId,
      }: {
        parentName: string;
        parentPhone: string;
        studentId?: number;
      }) => ({
        method: "POST",
        url: "owner/parent",
        body: {
          name: parentName,
          phoneNumber: parentPhone,
          studentId,
        },
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "student",
          id: args.studentId,
        },
        { type: "parent", id: "LIST" },
      ],
    }),

    getParents: builder.query<Parent[], void>({
      query: () => "owner/parents",
      transformResponse: (base) => GetParentsResponseSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((s) => s.userId),
          "parent"
        ),
    }),

    getParentById: builder.query<InferSchema<typeof ParentSchema>, string>({
      query: (id: string) => `owner/parent/${id}`,
      transformResponse: (data) => ParentSchema.parse(data),
      providesTags: ["parent"],
    }),

    retrieveFilteredParents: builder.query<
      InferSchema<typeof ParentsPagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/parents/list",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => ParentsPagedItemSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.items.map(
            (parent: InferSchema<typeof PagedParentSchema>) => parent.id
          ),
          "parent"
        ),
    }),

    updateParent: builder.mutation({
      query: (args: {
        parentId: Parent["userId"];
        name: string;
        phoneNumber: string;
        email: string | null;
        address: Address | null;
      }) => {
        const addressRequest: { [K in keyof Address]: Address[K] | null } =
          args.address !== null
            ? args.address
            : {
                street: null,
                addressNumber: null,
                addressComplement: null,
                zipCode: null,
                city: null,
                district: null,
                state: null,
              };

        return {
          url: `owner/parent/${args.parentId}`,
          method: "PUT",
          body: {
            name: args.name,
            phoneNumber: args.phoneNumber,
            email: args.email,
            ...addressRequest,
          },
        };
      },
      invalidatesTags: (_result, _error, args) => [
        {
          type: "parent",
          id: args.parentId,
        },
      ],
    }),

    deleteParent: builder.mutation({
      query: (args: { parentId: Parent["userId"] }) => ({
        url: `owner/parent/${args.parentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "parent",
          id: args.parentId,
        },
        {
          type: "parent",
          id: "LIST",
        },
      ],
    }),

    createSchool: builder.mutation({
      query: (args: {
        name: string;
        address: string | null;
        location: Location | null;
      }) => ({
        url: "owner/schools",
        method: "POST",
        body: {
          name: args.name,
          address: args.address,
          latitude: args.location?.lat ?? null,
          longitude: args.location?.lon ?? null,
        },
      }),
      invalidatesTags: () => [{ type: "school", id: "LIST" }],
    }),

    updateSchool: builder.mutation({
      query: (args: School) => ({
        url: `owner/schools/${args.id}`,
        method: "PUT",
        body: {
          name: args.name,
          address: args.address,
          latitude: args.latitude,
          longitude: args.longitude,
        },
      }),
      invalidatesTags: (_, a, args) => [
        {
          type: "school",
          id: args.id,
        },
      ],
    }),

    deleteSchool: builder.mutation({
      query: (args: { schoolId: number }) => ({
        url: `owner/schools/${args.schoolId}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "school",
          id: args.schoolId,
        },
      ],
    }),

    getSchools: builder.query<School[], void>({
      query: (_: void) => "owner/schools",
      transformResponse: (base) => GetSchoolsResponseSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((s) => s.id),
          "school"
        ),
    }),

    retrieveFilteredSchools: builder.query<
      InferSchema<typeof SchoolsPagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/schools/list",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => SchoolsPagedItemSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.items.map((parent) => parent.id),
          "school"
        ),
    }),

    getRoutes: builder.query<Route[], void>({
      query: () => "owner/routes",
      transformResponse: (base) => getRoutesResponseSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((s) => s.id),
          "route"
        ),
    }),

    retrieveRoutesList: builder.query<
      InferSchema<typeof OwnerRoutesListPagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/routes/list",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => OwnerRoutesListPagedItemSchema.parse(base),
    }),

    getInstallmentsByMonth: builder.query<
      InferSchema<typeof GetInstallmentsResponseSchema>,
      { month: number; year: number }
    >({
      query: (args) => ({
        url: `owner/installments/${args.month}/${args.year}`,
      }),
      transformResponse: (base) => GetInstallmentsResponseSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.installments.map(
            (i) => i.recurringPaymentId + i.installmentNumber
          ),
          "installment"
        ),
    }),

    getInstallments: builder.query<
      InferSchema<typeof GetInstallmentsResponseSchema>,
      {
        start: { year: number; month: number };
        end: { year: number; month: number };
      }
    >({
      query: (args) => ({
        url: "owner/installments",
        params: {
          startYear: args.start.year,
          startMonth: args.start.month,
          endYear: args.end.year,
          endMonth: args.end.month,
        },
      }),
      transformResponse: (base) => GetInstallmentsResponseSchema.parse(base),
      providesTags: (result) =>
        providesList(
          result?.installments.map(
            (i) => i.recurringPaymentId + i.installmentNumber
          ),
          "installment"
        ),
    }),

    getRecurrences: builder.query<Recurrence[], string>({
      query: (parentId) =>
        parentId.length > 0
          ? {
              url: "owner/recurrences",
              params: {
                parentId: parentId,
              },
            }
          : {
              url: "owner/recurrences",
            },
      transformResponse: (base) =>
        s
          .object({
            recurrenceId: s.string(),
            value: s.number(),
            parentName: s.string(),
            parentId: s.string(),
            billingDay: s.number(),
            description: s.string(),
            startDate: s.string().datetime(),
            endDate: s.string().datetime(),
            creationDate: s.string().datetime(),
          })
          .array()
          .parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((r) => r.recurrenceId),
          "recurrence"
        ),
    }),

    updateInstallmentStatus: builder.mutation({
      query: (args: {
        recurringPaymentId: string;
        installmentNumber: number;
        status: InstallmentStatus;
      }) => ({
        url: `owner/recurrence/installment/status`,
        method: "PUT",
        body: args,
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "installment",
          id: args.recurringPaymentId + args.installmentNumber,
        },
      ],
    }),

    cancelInstallmentStatus: builder.mutation({
      query: (args: {
        recurringPaymentId: string;
        installmentNumber: number;
        dueDate: string;
      }) => ({
        url: "owner/recurrence/installment/cancel-status",
        method: "POST",
        body: args,
      }),
      invalidatesTags: (result, error, args) => [
        {
          type: "installment",
          id: args.recurringPaymentId + args.installmentNumber,
        },
      ],
    }),

    retrieveFilteredExecutedRoutes: builder.query<
      InferSchema<typeof ExecutedRoutePagedItemSchema>,
      RetrieveResource
    >({
      query: (args: RetrieveResource) => ({
        url: "owner/routes/history",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => ExecutedRoutePagedItemSchema.parse(base),
      providesTags: (result) => [{ type: "routes-history", id: "LIST" }],
    }),

    getExecutedRoute: builder.query<
      InferSchema<typeof fullExecutedRouteSchema>,
      string
    >({
      query: (routeId) => ({ url: `owner/routes/history/${routeId}` }),
      transformResponse: (base) => fullExecutedRouteSchema.parse(base),
      providesTags: (result) => [
        { type: "routes-history", id: result?.executedRouteId },
      ],
    }),

    getReport: builder.query<{ description: string; url: string }, void>({
      query: () => ({ url: "owner/reports" }),
    }),

    getCurrentSubscription: builder.query({
      query: (_: void) => `owner/latest-subscription`,
      transformResponse: (base) => subscriptionSchema.parse(base),
    }),

    getInvoices: builder.query({
      query: (_: void) => `owner/invoices`,
      transformResponse: (base) => InvoicesSchema.parse(base),
    }),

    getDrivers: builder.query<InferSchema<typeof DriverSchema>[], void>({
      query: (_: void) => `owner/drivers`,
      transformResponse: (base) => DriverSchema.array().parse(base),
      providesTags: (result) =>
        providesList(
          result?.map((driver) => driver.id),
          "driver"
        ),
    }),

    createDriver: builder.mutation({
      query: (args: {
        name: string;
        email: string;
        password: string;
        phoneNumber: string;
        vanId: number;
      }) => ({
        url: "owner/drivers",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => CreateDriverResponseSchema.parse(base),
      invalidatesTags: (_result, _error, args) => [
        { type: "driver", id: "LIST" },
        { type: "van", id: args.vanId },
      ],
    }),

    updateDriver: builder.mutation({
      query: (args: {
        driverId: string;
        name: string;
        vanId: number;
        phoneNumber: string | null;
        whatsAppContact: string | null;
      }) => ({
        url: `owner/driver/${args.driverId}`,
        method: "PUT",
        body: args,
      }),
      invalidatesTags: (_result, _error, args) => [
        {
          type: "driver",
          id: args.driverId,
        },
        {
          type: "van",
          id: args.vanId,
        },
      ],
    }),

    resetDriverPassword: builder.mutation({
      query: (args: { driverId: string; password: string }) => ({
        url: `owner/driver/reset-password/${args.driverId}`,
        method: "PUT",
        body: { password: args.password },
      }),
    }),

    deleteDriver: builder.mutation({
      query: (args: { driverId: string; vanId: number }) => ({
        url: `owner/driver/${args.driverId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => [
        {
          type: "driver",
          id: args.driverId,
        },
        {
          type: "van",
          id: args.vanId,
        },
      ],
    }),

    getScopes: builder.query<InferSchema<typeof ScopesApiResponseSchema>, void>(
      {
        query: () => "admin/scopes",
        transformResponse: (base) => ScopesApiResponseSchema.parse(base),
        providesTags: ["scopes"],
      }
    ),

    deleteAccount: builder.mutation({
      query: (_: void) => ({
        url: "admin/delete-account",
        method: "DELETE",
      }),
    }),

    login: builder.mutation<
      s.infer<typeof LoginResponseSchema>,
      { username: string; password: string }
    >({
      query: (args: { username: string; password: string }) => ({
        url: "admin/login",
        method: "POST",
        body: args,
      }),
      transformResponse: (base) => LoginResponseSchema.parse(base),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const accessToken = (await queryFulfilled).data.accessToken;
        dispatch(loggedInAction(accessToken));
      },
    }),

    keycloakLogin: builder.mutation<
      s.infer<typeof KeycloakLoginResponseSchema>,
      { username: string; password: string }
    >({
      query: (args: { username: string; password: string }) => ({
        url: "keycloak",
        method: "POST",
        contentType: "application/x-www-form-urlencoded",
        body: new URLSearchParams({
          username: args.username,
          password: args.password,
          grant_type: "password",
          client_id: "myclient",
        }),
      }),
      transformResponse: (base) => KeycloakLoginResponseSchema.parse(base),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const accessToken = (await queryFulfilled).data.access_token;
        dispatch(loggedInAction(accessToken));
      },
    }),

    recoverPassword: builder.mutation({
      query: (args: { email: string }) => ({
        url: "recover-password",
        method: "PUT",
        body: args,
      }),
    }),

    resetPassword: builder.mutation({
      query: (args: { email: string; token: string; password: string }) => ({
        url: "reset-password",
        method: "PUT",
        body: args,
      }),
    }),

    getAccountInfo: builder.query<AccountInfo, void>({
      query: () => "owner/user",
      transformResponse: (base) => accountInfoSchema.parse(base),
      providesTags: ["account-info"],
    }),

    putAccountInfo: builder.mutation({
      query: (args: { name: string }) => ({
        url: "owner/user",
        method: "PUT",
        body: args,
      }),
      invalidatesTags: ["account-info"],
    }),

    putPassword: builder.mutation({
      query: (args: { currentPassword: string; newPassword: string }) => ({
        url: "change-password",
        method: "PUT",
        body: args,
      }),
    }),

    putOrganizationInfo: builder.mutation({
      query: (args: OrganizationInfo) => ({
        url: "owner/organization",
        method: "PUT",
        body: args,
      }),
      invalidatesTags: ["account-info"],
    }),

    getCompletedTutorials: builder.query({
      query: (_: void) => "owner/tutorials",
      providesTags: ["tutorials"],
      transformResponse: (base) => completedTutorialsSchema.parse(base),
    }),

    getUserSuggestions: builder.query({
      query: (_: void) => "owner/suggestions",
      providesTags: ["suggestions"],
      transformResponse: (base) => getSuggestionsResponseSchema.parse(base),
    }),

    sendSuggestionResponse: builder.mutation({
      query: (args: PostSuggestionResponse) => ({
        url: "owner/suggestions",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["suggestions"],
    }),

    postImpersonate: builder.mutation({
      query: (args: { user: string }) => ({
        url: "impersonate",
        method: "POST",
        body: args,
      }),
      invalidatesTags: tags,
    }),

    cleanImpersonate: builder.mutation<void, void>({
      query: () => ({
        url: "impersonate",
        method: "PUT",
      }),
      invalidatesTags: tags,
    }),
  }),
});

export default vanescolaApi;
export const {
  useResendAccountActivationMutation,
  useUpdateAccountActivationMutation,
  useGetVansQuery,
  useRetrieveFilteredVansQuery,
  useUpdateVanMutation,
  useDeleteVanMutation,
  useCheckVanCanBeDeletedQuery,
  useGetCurrentRoutesQuery,
  useCreateRecurrenceMutation,
  useDeleteRecurrenceMutation,
  useGetInstallmentsQuery,
  useGetRecurrencesQuery,
  useGetRoutesQuery,
  useRetrieveRoutesListQuery,
  useGetInstallmentsByMonthQuery,
  useUpdateInstallmentStatusMutation,
  useCancelInstallmentStatusMutation,
  useCreateStudentMutation,
  useGetStudentsQuery,
  useRetrieveFilteredStudentsQuery,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
  useCreateParentMutation,
  useGetParentsQuery,
  useGetParentByIdQuery,
  useRetrieveFilteredParentsQuery,
  useUpdateParentMutation,
  useDeleteParentMutation,
  useCreateStudentParentAssociationMutation,
  useDeleteStudentParentAssociationMutation,
  useCreateSchoolMutation,
  useGetSchoolsQuery,
  useRetrieveFilteredSchoolsQuery,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useDeleteRouteMutation,
  useRetrieveFilteredExecutedRoutesQuery,
  useGetExecutedRouteQuery,
  useGetReportQuery,
  useGetCurrentSubscriptionQuery,
  useGetInvoicesQuery,
  useGetDriversQuery,
  useUpdateDriverMutation,
  useResetDriverPasswordMutation,
  useDeleteDriverMutation,
  useGetScopesQuery,
  useGetResourceQueryOptionsQuery,
  useCreateVanMutation,
  useCreateDriverMutation,
  useDeleteAccountMutation,
  useLoginMutation,
  useRecoverPasswordMutation,
  useResetPasswordMutation,
  useGetAccountInfoQuery,
  usePutAccountInfoMutation,
  usePutPasswordMutation,
  usePutOrganizationInfoMutation,
  useGetCompletedTutorialsQuery,
  useGetUserSuggestionsQuery,
  useSendSuggestionResponseMutation,
  useRetrieveFilteredDriversQuery,
  useUpdateSchoolMutation,
  useDeleteSchoolMutation,
  usePostImpersonateMutation,
  useCleanImpersonateMutation,
  useKeycloakLoginMutation,
} = vanescolaApi;
